import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { orderBy, filter, find } from 'lodash';
import xss from 'xss';

import Layout from '../components/Layout';
import Experience from '../components/sections/Experience';
import VideoModalPreview from '../components/VideoModalPreview';
import Button from '../components/Button';

import DataLunchAndLearn from '../data/lunchAndLearn.json';
import DataVideoTutorials from '../data/videoTutorials.json';
import DataCluelessComputing from '../data/cluelessComputing.json';
import DataBlogCategories from '../data/blogCategories.json';

import BackgroundSrc from '../img/svg/pageServices/hero_bg.svg';
import Illustration from '../img/svg/pageKnowledge/hero_illustration.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

const categories = [
  {
    name: "Whitepaper",
    data: "whitepapers"
  },
  {
    name: "Conferences",
    data: "conferences"
  },
  {
    name: "Blog",
    data: "blogSection"
  },
  {
    name: "Contributions",
    data: "contributors"
  },
  {
    name: "E-book",
    data: "ebooks"
  },
  {
    name: "Lunch & Learn",
    data: "lunchlearn"
  },
  {
    name: "Podcasts",
    data: "radioDataPodcast"
  },
  {
    name: "Webinars",
    data: "webinars"
  },
  {
    name: "Video Tutorials",
    data: "videoTutorials"
  },
  {
    name: "Clueless Computing",
    data: "cluelessComputing"
  }
];

class KnowledgePageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFiltered: [],
      selectedCategory: 'Whitepaper',
      selectedMore: false,
      isMainSVGLoaded: false
    };
  }

  componentDidMount() {
    this.getFilteredBlogs();

    // set selected tab from url hash
    let hash = window.location.hash;
    if(hash) {
      hash = hash.substring(1).replace(/%20/g, " ");
      let category = find(categories, {name: hash});
      if(category) {
        this.handleTab(hash)
      }
    }
  }

  handleTab(e) {
    this.setState({
      selectedCategory: e,
      selectedMore: false
    }, () => {
      this.getFilteredBlogs();
    });
    window.location.hash = e;
  }

  handleMore() {
    this.setState({
      selectedMore: true
    }, () => {
      this.getFilteredBlogs();
    });
  }

  tabSelected(e) {
    let { selectedCategory } = this.state;

    return e === selectedCategory ? true : false
  }

  getFilteredBlogs() {
    let data;
    let { 
      blogs, 
      conferences, 
      webinars, 
      contributors, 
      blogSection, 
      ebooks, 
      lunchlearn,
      radioDataPodcast
    } = this.props;
    let { selectedCategory, selectedMore } = this.state;

    if (selectedCategory == "Blog") {
      data = [];
      const excludedNames = blogSection.excludeAuthors.map(x => x.label);

      blogs.forEach(a => {
        if (!excludedNames.includes(a.author && a.author[0].label)) {
          data.push(a);
        }
      });

      data = data.slice(0, 5);
    } else if (selectedCategory == "Conferences") {
      data = conferences.items;
    } else if (selectedCategory == "Webinars") {
      data = webinars.items;
    } else if (selectedCategory == "Contributions") {
      data = contributors.items;
    } else if (selectedCategory == "Lunch & Learn") {
      data =
        DataLunchAndLearn.items && DataLunchAndLearn.items.length && lunchlearn && lunchlearn.items && lunchlearn.items.length
          ? DataLunchAndLearn.items.filter(o => lunchlearn.items.find(o2 => o.id == o2.value)) : null; // must be ==
    } else if (selectedCategory == "E-book") {
      data = ebooks.items;
    } else if (selectedCategory == "Video Tutorials") {
      data = DataVideoTutorials.items;
    } else if (selectedCategory == "Clueless Computing") {
      data = DataCluelessComputing.items;
    } else if (selectedCategory == "Podcasts") {
      // data = radioDataPodcast.items;
      data = ['1']; // fake data, should be 1
    } else {
      // for blogs (Whitepaper)
      let catFiltered = DataBlogCategories.items.find(x => x.name === selectedCategory);
      data = filter(blogs, function (o) {
        return o.category == catFiltered.id;
      });
    }

    data = selectedMore ? data : data.slice(0, 5);

    // hide button if data less
    if(data.length < 5) {
      this.setState({selectedMore: true});
    }

    this.setState({ dataFiltered: data });
  }

  render() {
    const { dataFiltered, isMainSVGLoaded, selectedCategory, selectedMore } = this.state;
    const { hero, experience } = this.props;

    const classNameBackground = classNames('Knowledge__hero__background', {
      'Knowledge__hero__background--noImage': !isMainSVGLoaded
    });

    let itemsText = '';
    let itemsTextVal = find(categories, (o) => (o.name === selectedCategory));
    if (typeof itemsTextVal === 'object' && itemsTextVal !== null) {
      itemsText = this.props[itemsTextVal.data]?.text;
    }

    const optionsXSS = {
      whiteList: {
        iframe: ["src", "frameBorder", "width", "height", "allow", "title", "scrolling", "style"],
      },
    };
    const myxss = new xss.FilterXSS(optionsXSS);

    return (
      <div className="Knowledge">
        <section className="Knowledge__hero">
          <div className="Layout__background">
            <SVG src={ImageStarsSrc} className="Layout__stars" />
          </div>

          <div className={classNameBackground}>
            <SVG
              src={BackgroundSrc}
              uniquifyIDs={false}
              className="Knowledge__hero__background-image"
              onError={error => console.log(error.message)}
              onLoad={(src, hasCache) => this.setState({ isMainSVGLoaded: true })}
            />
          </div>

          <div className="Knowledge__hero__container">
            <div className="Knowledge__hero__content">
              <div className="Knowledge__hero__content__top">
                <h1 className="Knowledge__hero__title">
                  <ReactMarkdown
                    children={hero.title}
                    rehypePlugins={[rehypeRaw]}
                  />
                </h1>
                <div className="Knowledge__hero__text">
                  <ReactMarkdown
                    children={hero.description}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </div>
            </div>

            <div className="Knowledge__hero__illustration">
              <img
                src={Illustration}
                alt="servers"
                className="Knowledge__hero__image"
              />
            </div>

          </div>
        </section>

        <section className="Knowledge__blogs">
          <div className="Knowledge__container">

            <div className="Knowledge__container__tags">
              <div className="Knowledge__tags">
                {categories != null && categories.map((e, i) => (
                  <div
                    key={i}
                    className={classNames('Knowledge__tags__item', {
                      'Knowledge__tags__item--selected': this.tabSelected(e.name)
                    })}
                    onClick={() => this.handleTab(e.name)}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            </div>
            
            <div className="Knowledge__blogs__container">
              <div className="Knowledge__itemsText">
                {itemsText}
              </div>

              <div className="Knowledge__items">
                {dataFiltered && dataFiltered.map((e, i) => {
                  if (selectedCategory === "Webinars" || selectedCategory === "Conferences" ||
                  selectedCategory === "Video Tutorials" || selectedCategory === "Clueless Computing") {
                    return (
                      <div className="Knowledge__item Knowledge__item--noHover" key={i}>
                        <div className="Knowledge__item__col">
                          <VideoModalPreview
                            className="Knowledge__item__video"
                            videoID={e.videoID}
                            channel={e.channel}
                            image={e.image?.publicURL ? e.image.publicURL : e.image}
                          />
                        </div>
                        <div className="Knowledge__item__col">
                          <h3 className="Knowledge__item__title">{e.title}</h3>
                          <div className="Knowledge__item__text">
                            {e.text}
                          </div>
                          {e.person &&
                            <div className="Knowledge__item__authors">
                              <span className="Knowledge__item__authorLabel">Author: </span>
                              {e.person}
                            </div>
                          }
                        </div>
                      </div>
                    )
                  } else if (selectedCategory === "Contributions") {
                    return (
                      <div className="Knowledge__item" key={i}>
                        {e.image &&
                          <div className="Knowledge__item__col">
                            <img src={e.image && e.image.publicURL} alt="" />
                          </div>
                        }
                        <div
                          className={classNames('Knowledge__item__col', {
                            'Knowledge__item__col--full': e.image && e.image.publicURL ? false : true
                          })}
                        >
                          <h3 className="Knowledge__item__title">{e.title}</h3>
                          <div className="Knowledge__item__text">
                            {e.text}
                          </div>
                        </div>
                        <a className="Knowledge__item__link" href={e.link} target="_blank" rel="noreferrer">Read more</a>
                      </div>
                    )
                  } else if (selectedCategory === "Lunch & Learn") {
                    return (
                      <div
                        className={classNames('Knowledge__item', {
                          'Knowledge__item--noHover': e.link ? false : true
                        })}
                        key={i}
                      >
                        {e.image &&
                          <div className="Knowledge__item__col">
                            <img src={e.image} alt={e.title + 'lunch & learn'} />
                          </div>
                        }
                        <div
                          className={classNames('Knowledge__item__col', {
                            'Knowledge__item__col--full': e.image && e.image.publicURL ? false : true
                          })}
                        >
                          <h3 className="Knowledge__item__title">{e.title}</h3>
                          <div className="Knowledge__item__text">
                            {e.text}
                          </div>
                        </div>
                        {e.link &&
                          <Link className="Knowledge__item__link" href={e.link} target="_blank" rel="noreferrer">Read more</Link>
                        }
                      </div>
                    )
                  } else if (selectedCategory === "E-book") {
                    return (
                      <div className={classNames('Knowledge__item', {
                        'Knowledge__item--noHover': e.link ? false : true
                      })}
                        key={i}
                      >
                        <div className="Knowledge__item__col">
                          <img src={e.image && e.image.publicURL} alt={e.title + 'e-book'} />
                        </div>
                        <div className="Knowledge__item__col">
                          <h3 className="Knowledge__item__title">{e.title}</h3>
                          {e.text ?
                            <div className="Knowledge__item__text">
                              {e.text}
                            </div>
                            : null}
                          {e.author &&
                            <div className="Knowledge__item__authors">
                              <span className="Knowledge__item__authorLabel">Author: </span>
                              {e.author && e.author[0].label}
                            </div>
                          }
                        </div>
                        {e.link ? <Link className="Knowledge__item__link" to={e.link} target="_blank" rel="noreferrer">Read more</Link> : null}
                      </div>
                    )
                  } else if (selectedCategory === "Podcasts") {
                    return (
                      <iframe src="https://embed.acast.com/624cc6e4a259030012cf5c87?cover=false&feed=true&theme=light" frameBorder="0" width="100%" height="630px"></iframe>
                      // <div className="Knowledge__itemEmpty" key={i}>
                      //   <div dangerouslySetInnerHTML={{ __html: myxss.process(e.iframe) }} />
                      // </div>
                    )
                  } else {
                    return (
                      <div className="Knowledge__item" key={i}>
                        <div className="Knowledge__item__col">
                          <img src={e.image && e.image.publicURL} alt="" />
                        </div>
                        <div className="Knowledge__item__col">
                          <h3 className="Knowledge__item__title">{e.title}</h3>
                          {e.introTextKnowledgeBase
                            ?
                            <div className="Knowledge__item__text">
                              {e.introTextKnowledgeBase}
                            </div>
                            :
                            <div className="Knowledge__item__text">
                              {e.excerpt}
                            </div>
                          }
                          {e.author &&
                            <div className="Knowledge__item__authors">
                              <span className="Knowledge__item__authorLabel">Author: </span>
                              {e.author && e.author[0].label}
                            </div>
                          }
                        </div>
                        <Link className="Knowledge__item__link" to={e.slug}>Read more</Link>
                      </div>
                    )
                  }
                })}
              </div>
              <div className="Knowledge__itemsBtn">
                {(selectedCategory === "Conferences" || selectedCategory === "Webinars") &&
                  <Button
                    className="Knowledge__itemsBtn__btn"
                    href={'https://www.youtube.com/channel/UCasSODfRGOcXOPooM6OvSig'}
                    target="_blank"
                  >
                    See more videos
                  </Button>
                }
                {selectedCategory === "Blog" &&
                  <Button
                    className="Knowledge__itemsBtn__btn"
                    to={'/blog'}
                  >
                    Read more
                  </Button>
                }
                {selectedCategory === "Contributions" &&
                  <Button
                    className="Knowledge__itemsBtn__btn"
                    href={'https://github.com/getindata'}
                    target="_blank"
                  >
                    See our Github
                  </Button>
                }
                {selectedCategory === "Podcasts" &&
                  <Button
                    className="Knowledge__itemsBtn__btn"
                    href={'https://shows.acast.com/radio-data'}
                    target="_blank"
                  >
                    See more
                  </Button>
                }
                {["Lunch & Learn", "Whitepaper"].includes(selectedCategory) && !selectedMore &&
                  <Button
                    className="Knowledge__itemsBtn__btn"
                    onClick={() => this.handleMore()}
                  >
                    See more
                  </Button>
                }
              </div>
            </div>
          </div>
        </section>

        <Experience
          heading={experience.heading}
          items={experience.items}
        />
      </div>
    )
  }
}

const KnowledgePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  let dataBlogs = data.blogs.edges.map(function (elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;

    return {
      ...{ id: elem.node.id },
      ...{ excerpt: elem.node.excerpt },
      ...{ html: elem.node.html },
      ...elem.node.frontmatter,
      slug: slug // should be last 
    };
  });

  // order by 'order' and 'date' fields
  dataBlogs = orderBy(dataBlogs, [
    (item) => (item.order),
    (item) => (item.date)
  ], ["asc", "desc"]);

  const mergedData = {
    ...frontmatter,
    ...{ blogs: dataBlogs },
    ...{ blogSection: frontmatter.blogs }
  };

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <KnowledgePageTemplate {...mergedData} />
    </Layout>
  );
};

KnowledgePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default KnowledgePage;

export const knowledgePageQuery = graphql`query KnowledgePageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "knowledge-base-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        description
      }
      conferences {
        text
        items {
          image {
            publicURL
            name
          }
          title
          text
          person
          videoID
          channel
        }
      }
      webinars {
        text
        items {
          image {
            publicURL
            name
          }
          title
          text
          person
          videoID
          channel
        }
      }
      blogs {
        text
        excludeAuthors {
          label
          value
        }
      }
      experience {
        heading
        items {
          text
          title
          icon {
            publicURL
            name
          }
          slug
        }
      }
      whitepapers {
        text
      }
      contributors {
        text
        items {
          title
          text
          link
        }
      }
      lunchlearn {
        text
        items {
          label
          value
        }
      }
      radioDataPodcast {
        text
        items {
          iframe
        }
      }
      ebooks {
        text
        items {
          image {
            publicURL
            name
          }
          title
          text
          author {
            label
            value
          }
          link
        }
      }
    }
  }
  blogs: allMarkdownRemark(
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 150)
        id
        fields {
          slug
        }
        html
        frontmatter {
          order
          title
          description
          slug
          image {
            publicURL
            name
          }
          introTextKnowledgeBase
          category
          author {
            label
            value
          }
          date
        }
      }
    }
  }
}`;
