import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import IconedText from '../IconedText';
import Button from '../Button';
import Markdown from '../Markdown';

import Bars1Src from '../../img/svg/pageHome/bar-3.svg';
import Bars2Src from '../../img/svg/pageHome/bar-4.svg';

class Experience extends React.Component {

  static defaultProps = {
    tagName: 'section',
    className: '',
    heading: '',
    items: []
  }

  render() {
    const { className, tagName, heading, items } = this.props;
    const TagName = tagName;

    return (
      <TagName className={classNames(className, 'Experience')}>
        <div className="Experience__container">
          {heading && heading.length &&
          <div className="Experience__title-wrapper">
            <SVG
              src={Bars2Src}
              className="Experience__bar Experience__bar--1"
            />

            <h2 className="Experience__title">{heading}</h2>

            <SVG
              src={Bars1Src}
              className="Experience__bar Experience__bar--2"
            />
          </div>
          }

          <ul className="Experience__items">
            {items.map((item, i) => (
              <li key={i} className="Experience__item Experience__item--spaceBetween">
              <IconedText
                tagName="div"
                iconSrc={item.icon && item.icon.publicURL}
                title={item.title ? item.title : ''}
                variants={['large']}
              >
                <Markdown content={item.text} />
              </IconedText>
              <Button 
                className="Experience__item__btn"
                variants={['full']}
                to={item.slug}
              >
                Read More
              </Button>
            </li>
            ))}
          </ul>
        </div>
      </TagName>
    );
  }
}

Experience.propTypes = {
  items: PropTypes.array
};

export default Experience;
